<template>
  <div class="shop-preview">
    <n-link :to="{name: 'stores.index', params:{id: shop.id},}">
      <div>
        <div class="title">{{ shop.title }}</div>
        <div>
          <star-rating :read-only="true" :star-size="15" :rating="shop.rating" :increment="0.01"></star-rating>
        </div>
      </div>

      <div>
        <n-icon icon="angle-right" />
      </div>
    </n-link>
  </div>
</template>

<script>
export default {
  name: 'ShopPreview',
  props: {
    shop: { type: Object, required: true, },
  },
}
</script>

<style scoped lang="scss">
.shop-preview {
  background: #DFF1D8;
  border-radius: 5px;
  padding: 12px 15px 12px;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    display: flex;
    align-shops: center;
    justify-content: space-between;
    text-decoration: none;
  }

  .title {
    font-size: 18px;
    color: #000;
  }

  &::v-deep .vue-star-rating-rating-text {
    font-size: 12px;
    color: #BABABA;
  }
}
</style>
