<template>
  <div class="shops-page">
    <top-bar/>
    <page-title title="Магазины" description="Выберете нужный вам магазин"/>
    <n-loader :loading="$var('load')"></n-loader>

    <div class="container">
      <sort :is-list="false" model="store" @filter="load" />
      <shop-preview v-for="item in shops" :key="item.id" :shop="item"/>
    </div>
  </div>
</template>

<script>
import ShopPreview from '../components/shopPreview'

export default {
  name: 'Index',
  components: { ShopPreview, },
  data() {
    return {
      shops: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load(filter = (v) => v) {
      this.$var('load', true)
      filter($api.app.stores.get()).then((response) => {
        this.shops = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.shops-page {
  .container {
    min-height: 200px;

    &::v-deep .n-loader .n-line {
      top: -20px;
    }
  }

  &::v-deep .n-select{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .search-wrap {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    height: 47px;
    padding: 0 15px;
    border-radius: 10px;
    margin-right: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


    path {
      fill: #BABABA !important;
    }

    input {
      width: 100%;
      border: none;
      padding-left: 10px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 14px;
        padding: 3px 0 5px;
        color: #BABABA;
      }
    }
  }

  .sort-and-search {
    display: flex;
    margin-bottom: 20px;
  }
}
</style>
